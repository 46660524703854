<template>
  <div>
    <div @click="listShow">
      <slot></slot>
    </div>
    <el-dialog append-to-body :title="dialogTitle" :visible.sync="show">
      <div class="flex-def flex-zBetween">
        <div>
          <el-input style="width: 19rem" v-model="searchForm.keyword"
                    placeholder="输入用户昵称/ID/手机号/激活码搜索"></el-input>
          <el-select v-model="searchForm.use_state">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="未使用" :value="1"></el-option>
            <el-option label="已使用" :value="2"></el-option>
          </el-select>
          <el-button-group style="margin-left: 1rem">
            <el-button @click="searchGo">搜索</el-button>
            <el-button @click="searchReset">全部</el-button>
          </el-button-group>
        </div>
        <el-button @click="exportExcel" type="danger">导出</el-button>
      </div>
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="code" label="激活码"></el-table-column>
        <el-table-column prop="sn_code" label="激活码编号"></el-table-column>
        <el-table-column label="用户信息">
          <template #default="s">
            <div class="flex-def" v-if="s.row.use_user.id > 0">
              <img v-if="s.row.use_user.avatar" :src="s.row.use_user.avatar | tomedia"
                   style="height: 3rem;width: 3rem;border-radius: 5px;margin-right: 10px" alt="">
              <div>
                <div>{{ s.row.use_user.nickname }}({{ s.row.use_user.id }})</div>
                <div>{{ s.row.use_user.phone }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="使用状态">
          <template #default="s">
            <span :style="`color: ${s.row.used ? 'red' : '#3cc51f'}`">{{ s.row.used ? '已使用' : '未使用' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="生成时间">
          <template #default="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column label="激活时间">
          <template #default="s">{{ s.row.used_time | date }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <el-button v-if="!s.row.used" type="danger" plain @click="clear(s.row)">解绑</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "userCodeList",
  props: {
    uid: {
      type: Number,
      default: 0,
    },
    nickname: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      show: false,
      page: 1,
      searchForm: {
        keyword: "",
        use_state: 0,
      },
      list: [],
      total: 0,
      loading: false,
    }
  },
  mounted() {

  },
  computed: {
    dialogTitle() {
      return `【${this.nickname}】激活码明细`
    }
  },
  methods: {
    listShow() {
      this.show = true;
      this.load();
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    searchReset() {
      this.searchForm = {
        keyword: "",
        use_state: 0,
      }
      this.searchGo();
    },
    searchGo() {
      this.page = 1;
      this.list = [];
      this.load();
    },
    exportExcel(){
      this.$u.api.card.plugin.cdkey.export({
        ...this.searchForm,
        is_creator: true,
        uid: this.uid,
      }).then(res=>{
        window.open(res,"_blank");
      })
    },
    clear({id}) {
      this.$u.api.card.plugin.cdkey.clear({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.loading = true;
      this.$u.api.card.plugin.cdkey.search({
        page: this.page,
        ...this.searchForm,
        is_creator: true,
        uid: this.uid,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>