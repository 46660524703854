<template>
  <div>
    <el-button @click="dialogTableVisible=true" type="danger">添加用户</el-button>
    <el-dialog append-to-body title="选择用户" :visible.sync="dialogTableVisible">
      <div class="flex-def">
        <el-input style="width: 19rem" v-model="searchForm.keyword" placeholder="输入用户昵称/ID/手机号搜索"></el-input>
        <el-button-group style="margin-left: 1rem">
          <el-button @click="searchGo">搜索</el-button>
          <el-button @click="searchReset">全部</el-button>
        </el-button-group>
      </div>
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="nickname" label="昵称" ></el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <el-button @click="choose(s.row)">选中</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "userCreate",
  props:{

  },
  data(){
    return{
      dialogTableVisible:false,
      page:1,
      searchForm:{
        keyword:"",
      },
      list:[],
      total:0,
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    choose(e){
      this.dialogTableVisible =false;
      this.$emit("change",e);
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        keyword:"",
      }
      this.searchGo();
    },
    searchGo(){
      this.page = 1;
      this.list = [];
      this.load();
    },
    load(){
      this.loading = true;
      this.$u.api.card.plugin.cdkey.memberSearch({
        page:this.page,
        ...this.searchForm,
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>